.content.detail {
  .container .row {
    margin-bottom: 100px;
  }

  .header {
    span.badge {
      margin-right: 5px;
    }
  }

  .timerange {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 45%, $gray-300 50%, rgba(0, 0, 0, 0) 55%);

    .inner {
      display: flex;
      margin: 0 auto;
      background-color: $body-bg;
      row-gap: 20px;
      column-gap: 20px;
      flex-direction: column;

      .time {
        display: flex;
        column-gap: 20px;
        justify-content: center;
      }

      .scale {
        svg {
          margin-right: 8px;
        }
      }

      @media (min-width: 576px) {
        width: 66%;
      }
      @media (min-width: 768px) {
        width: 50%;
      }
      @media (min-width: 1440px) {
        justify-content: center;
        flex-direction: row;
      }
    }
  }

  h4.graphs-headline {
    margin-bottom: 0;
  }
}
