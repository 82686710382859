div.burnrate {
  margin: 10px;
  padding: 15px;
  background-color: white;
  border-radius: 5px;

  h5.graphs-headline {
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 5px;
  }
  .graphs-description{
    margin-left: 50px;
  }
}
